import type { ReactNode } from "react";
import React from "react";

import { gridSpacing } from "@lucernahealth/lucerna-health-ui";
import { css, styled } from "styled-components";
const Wrapper = styled.div`
  container-type: inline-size;
`;

const Body = styled.div<{ columnCount: number }>`
  width: 100%;
  margin-bottom: ${gridSpacing[5]}px;
  ${({ columnCount }) =>
    columnCount > 1
      ? css`
          * {
            box-sizing: border-box;
          }

          display: grid;
          grid-gap: ${gridSpacing[5]}px;
          grid-template-columns: repeat(2, 1fr);
          @container (min-width: ${columnCount === 4
            ? 1050
            : columnCount === 2
              ? 700
              : 800}px) {
            grid-template-rows: 1fr;
            grid-template-columns: repeat(${columnCount}, 1fr);
          }
          @container (max-width: 500px) {
            grid-template-rows: 1fr;
            grid-template-columns: 1fr;
          }
        `
      : ""}
`;

const Row = ({ children }: { children: ReactNode | ReactNode[] }) => {
  let _children: ReactNode[] = [];

  if (!Array.isArray(children) && typeof children === "object") {
    _children = [children];
  } else if (!Array.isArray(children)) {
    console.error("Row children must be react nodes");
    return null;
  } else {
    _children = children;
  }

  return (
    <Wrapper>
      <Body columnCount={_children.length}>
        {_children.map((child, key) => (
          <React.Fragment key={key}>{child}</React.Fragment>
        ))}
      </Body>
    </Wrapper>
  );
};

export default Row;
