import React from "react";

import { gridSpacing } from "@lucernahealth/lucerna-health-ui";
import { styled } from "styled-components";

import { colorTheme } from "@utils";

import { favoriteObject } from "~/src/utils/tsUtils";

const StyledButton = styled.i<{ favorite: boolean }>`
  color: ${(props) =>
    props.favorite
      ? colorTheme("warning")
      : colorTheme("neutralL3")} !important;
  font-size: 19px;
  margin-left: ${gridSpacing[2]}px;
  cursor: pointer;
`;

type FavoriteButtonProps = {
  favorite: boolean;
  objectId: string;
  objectType: string;
  refreshFavorites: () => void;
};

const FavoriteButton = ({
  favorite,
  objectId,
  objectType,
  refreshFavorites,
}: FavoriteButtonProps) => {
  const updateFavorite = async (
    e: React.MouseEvent<HTMLElement, MouseEvent>,
  ) => {
    e.preventDefault();
    await favoriteObject({ objectType, objectId, remove: favorite });
    refreshFavorites();
  };

  return (
    <StyledButton
      className={`fa-${favorite ? "solid" : "regular"} fa-star`}
      favorite={favorite}
      onClick={(e) => updateFavorite(e)}
    />
  );
};

export default FavoriteButton;
