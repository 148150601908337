import React, { useEffect, useState } from "react";

import PropTypes from "prop-types";
import { useDrop } from "react-dnd";
import { styled } from "styled-components";

const Container = styled.div`
  background: ${(props) =>
    props.isOver && !props.canDrop
      ? props.rejectColor
      : props.isOver
        ? props.acceptColor
        : props.defaultColor};
`;

function DragTarget(props) {
  const [canDrop, setCanDrop] = useState(true);
  const onDrop = (obj) => {
    if (props.filterSource && !props.filterSource(obj)) return;
    props.onDrop(obj);
  };

  const [{ item, isOver }, drop] = useDrop({
    accept: props.accept,
    drop: onDrop,
    collect: (monitor) => {
      return {
        isOver: monitor.isOver(),
        item: monitor.getItem(),
      };
    },
  });

  useEffect(() => {
    if (isOver) {
      if (props.onHover) {
        props.onHover();
      }

      if (props.filterSource) {
        setCanDrop(props.filterSource(item));
      }
    }
  }, [isOver]);

  return (
    <Container
      isOver={isOver}
      canDrop={canDrop}
      ref={drop}
      acceptColor={props.acceptColor}
      rejectColor={props.rejectColor}
      defaultColor={props.defaultColor}
      style={props.style}
    >
      {props.children}
    </Container>
  );
}

DragTarget.defaultProps = {
  acceptColor: "#8BC34A",
  rejectColor: "#ff8482",
  defaultColor: "#fff",
};

DragTarget.propTypes = {
  children: PropTypes.any,
  /** Array of strings for the acceptance of targets */
  accept: PropTypes.array.isRequired,
  /** Callback when a source is dropped, returns an object of the source */
  onDrop: PropTypes.func.isRequired,
  /** Callback when a something is hovering over the target */
  onHover: PropTypes.func,
  /** Custom function called when source is dragged over target, passes the source object and expects a boolean in return */
  filterSource: PropTypes.func,
  /** Background color of the target when source is dragged over and can be accepted */
  acceptColor: PropTypes.string,
  /** Default background color of the target */
  defaultColor: PropTypes.string,
  /** Background color of the target when source is dragged over and is rejected */
  rejectColor: PropTypes.string,
  /** Custom styled object */
  style: PropTypes.object,
};

export default DragTarget;
