import type { ReactNode, CSSProperties } from "react";
import React from "react";

import { gridSpacing } from "@lucernahealth/lucerna-health-ui";

import { colorTheme } from "@utils";

interface InfoTextProps {
  title?: string;
  content?: ReactNode;
  text?: string | null;
  style?: CSSProperties;
  cleanText?: boolean;
  testId?: string;
  children?: ReactNode;
  small?: boolean;
}

const InfoText = ({
  title,
  content,
  text,
  style,
  cleanText,
  testId,
  children,
  small = false,
}: InfoTextProps) => {
  if (text) {
    if (cleanText) {
      text = text.replaceAll("_", " ");
    }

    content = text;
  }

  if (cleanText && content && typeof content === "string") {
    content = content.replaceAll("_", " ");
  }

  return (
    <div
      style={{
        marginTop: gridSpacing[3],
        marginBottom: gridSpacing[3],
        ...style,
      }}
    >
      <p
        style={{
          color: colorTheme("neutralL1"),
          marginBottom: small ? 0 : gridSpacing[1],
          fontSize: small ? 12 : 14,
        }}
      >
        {title}
      </p>
      {children ? (
        children
      ) : (
        <p
          style={{
            textTransform: cleanText ? "capitalize" : "none",
            overflowWrap: "break-word",
            fontSize: small ? 14 : 16,
          }}
          data-testid={testId}
        >
          {content || "-"}
        </p>
      )}
    </div>
  );
};

export default InfoText;
