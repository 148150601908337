import React, { useContext } from "react";

import { DataViz } from "@commonComponents";
import moment from "moment";

import {
  totalViewPercentageChangeQuery,
  totalViewsQuery,
  averageNumberOfViewsQuery,
  viewsTrendQuery,
  averageGroupViewsQuery,
} from "./Queries";
import { PortalContext } from "../../Portal/Portal";
import {
  roundToTwoDecimals,
  userFilterDimensions,
  groupFilterDimensions,
  viewActivityFilterDimensions,
} from "../Common/userAnalyticsCommon";
import { useCubeApiQuery } from "~/src/utils/tsUtils";

const VisitsAnalysis = () => {
  const { filters, filterOrder } = useContext(PortalContext);

  DataViz.filterUtils.useFilterOptionsNormalized({
    dimensions: [
      ...viewActivityFilterDimensions,
      ...userFilterDimensions,
      ...groupFilterDimensions,
    ],
  });

  const {
    resultSet: totalViewsResult,
    error: totalViewsError,
    isLoading: totalViewsLoading,
  } = useCubeApiQuery(totalViewsQuery(filters, filterOrder));

  const {
    resultSet: totalViewsPercentageChangeResult,
    error: totalViewsPercentageChangeError,
    isLoading: totalViewsPercenageChangeLoading,
  } = useCubeApiQuery(totalViewPercentageChangeQuery(filters, filterOrder));

  const {
    resultSet: averageNumberOfViewsResult,
    error: averageNumberOfViewsError,
    isLoading: averageNumberOfViewsLoading,
  } = useCubeApiQuery(averageNumberOfViewsQuery(filters, filterOrder));

  const {
    resultSet: viewsTrendResult,
    error: viewsTrendError,
    isLoading: viewsTrendLoading,
  } = useCubeApiQuery(viewsTrendQuery(filters, filterOrder));
  const {
    resultSet: averageGroupViewsResult,
    error: averageGroupViewsError,
    isLoading: averageGroupViewsLoading,
  } = useCubeApiQuery(averageGroupViewsQuery(filters, filterOrder));

  const totalViewsData = totalViewsResult?.rawData()[0];

  const totalViewPercentageChangeData =
    totalViewsPercentageChangeResult?.rawData()[0];

  const averageNumberOfViewsData = averageNumberOfViewsResult?.rawData()[0];

  const averageGroupViewsData = roundToTwoDecimals(
    (
      (averageGroupViewsResult?.tablePivot() ?? []) as {
        "group.total_groups": string;
      }[]
    )
      .map((entry) => parseInt(entry["group.total_groups"]))
      .reduce((acc, val) => acc + val, 0) / 30,
  );

  const getGrowthLineTrend = () => {
    const rawData = (viewsTrendResult?.tablePivot() ?? []) as {
      "insights_dashboard_views.created.month": string;
      "insights_dashboard_views.total_insights_views": number | string;
    }[];

    const yearmo = [
      ...new Set(
        rawData
          .map((obj) =>
            moment(obj["insights_dashboard_views.created.month"], "YYYY-MM"),
          )
          .sort((a, b) => a.valueOf() - b.valueOf())
          .map((yearmo) => yearmo.format("MM/YYYY")),
      ),
    ];

    const parsedData = rawData.map((obj) => {
      const [year, month] =
        obj["insights_dashboard_views.created.month"].split("-");
      return {
        year: parseInt(year),
        month: parseInt(month) - 1, // Adjust for zero-based indexing of months
        totalViews: parseInt(
          obj["insights_dashboard_views.total_insights_views"]?.toString(),
        ),
      };
    });

    const groupedData = yearmo.map((ym) => {
      const [month, year] = ym.split("/");
      const filteredData = parsedData.filter(
        (data) =>
          data.year === parseInt(year) && data.month === parseInt(month) - 1,
      );
      return filteredData.length ? filteredData[0].totalViews : null;
    });

    const returnData = [
      {
        name: "Total Views",
        data: groupedData,
      },
    ];

    return {
      data: returnData,
      options: yearmo,
    };
  };

  const totalViewPercentageChange = roundToTwoDecimals(
    ((parseFloat(
      totalViewsData?.[
        "insights_dashboard_views.insights_views_count_recent_30_days"
      ] ?? "0",
    ) -
      parseFloat(
        totalViewPercentageChangeData?.[
          "insights_dashboard_views.insights_views_count_previous_30_days"
        ] ?? "0",
      )) /
      parseFloat(
        totalViewPercentageChangeData?.[
          "insights_dashboard_views.insights_views_count_previous_30_days"
        ] ?? "1",
      ) ?? 0) * 100,
  );

  return (
    <>
      <DataViz.Row>
        <DataViz.NumberChart
          loading={totalViewsLoading || totalViewsPercenageChangeLoading}
          error={
            (totalViewsError || totalViewsPercentageChangeError) &&
            "Something went wrong"
          }
          type="metric"
          toolTip="Total number of views for the last 30 days and the percentage change from the previous 30 days"
          title="Total Views (30 Days)"
          unit="views"
          numerator={parseFloat(
            totalViewsData?.[
              "insights_dashboard_views.insights_views_count_recent_30_days"
            ] ?? "",
          )}
          trendDirection={totalViewPercentageChange > 0 ? "up" : "down"}
          trendPercentage={totalViewPercentageChange}
        />

        <DataViz.NumberChart
          loading={averageNumberOfViewsLoading}
          error={averageNumberOfViewsError && "Something went wrong"}
          type="metric"
          toolTip="Average number of dashboard views per day for the last 30 days"
          unit="views"
          title="Average Views (30 Days) "
          numerator={roundToTwoDecimals(
            parseFloat(
              averageNumberOfViewsData?.[
                "insights_dashboard_views.avg_insights_views_per_day_last_30_days"
              ] ?? "",
            ),
          )}
        />
        <DataViz.NumberChart
          loading={averageGroupViewsLoading}
          error={averageGroupViewsError && "Something went wrong"}
          type="metric"
          toolTip="Average number of dashboard views per user group per day for the last 30 days"
          unit="views"
          title="Average Views Per Group (30 Days)"
          numerator={averageGroupViewsData}
        />
      </DataViz.Row>
      <DataViz.Row>
        <DataViz.LineChart
          title="Views Trend"
          toolTip="Analysis of monthly dashboard views"
          loading={viewsTrendLoading}
          error={viewsTrendError && "Something went wrong"}
          yAxisLabel="Total Views"
          {...getGrowthLineTrend()}
        />
      </DataViz.Row>
    </>
  );
};

export default VisitsAnalysis;
