import React, { useState } from "react";

// import { Slider } from "@commonComponents";
import {
  SingleDatePicker,
  gridSpacing,
  Select,
  Text,
} from "@lucernahealth/lucerna-health-ui";

import type { FilterOptionProps } from "./AdvancedFilters";

export type OptionsProps = {
  onChange: (id: string, e: string) => void;
  filters: Record<string, string[]>;
  closeMenu?: () => void;
  useOnBlur?: boolean;
};

const Options = (
  props: OptionsProps & Omit<FilterOptionProps, "label" | "value">,
) => {
  const {
    identifier,
    type,
    gt_suffix,
    lt_suffix,
    onChange,
    filters,
    closeMenu,
    useOnBlur,
  } = props;

  const [currentValue, setCurrentValue] = useState<string | undefined>(
    filters?.[identifier ?? ""]?.[0],
  );

  if (!identifier) {
    console.error("No identifier provided for filter options");
    return null;
  }

  // @ts-expect-error - I think this type is getting passed from the API in the consumer profile so may need to alter the ts for this
  // ignoring now to make sure nothing breaks
  if (type === "AGE_BEFORE_AFTER") {
    // Temporarily commenting out the slider as it is very difficult to implement due to `onChangeComplete` not working

    const gtDate = filters?.[`${identifier}${gt_suffix}`]?.[0];
    const ltDate = filters?.[`${identifier}${lt_suffix}`]?.[0];

    // const gtAge = moment().diff(gtDate, "years") || 100;
    // const ltAge = moment().diff(ltDate, "years") || 0;

    // const getAgeDate = (age: number) =>
    //   moment()
    //     .startOf("year")
    //     .subtract(age, "years")
    //     .toISOString()
    //     .split("T")[0];

    return (
      <>
        {/* <div
          style={{
            display: "flex",
            justifyContent: "center",
            gap: gridSpacing[3],
          }}
        >
          <p>{ltAge}</p>
          <p>-</p>
          <p>{gtAge}</p>
        </div> 
       <Slider
          // This Slider is not in TS yet so going to ignore the errors for now
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          range
          value={[ltAge, gtAge]}
          onChange={(e: number[]) => {
            onChange({
              [`${identifier}${lt_suffix}`]: getAgeDate(e[0]),
              [`${identifier}${gt_suffix}`]: getAgeDate(e[1]),
            });
          }}
          count={1}
          min={0}
          max={100}
          step={1}
          allowCross={false}
        /> */}
        <div
          style={{
            display: "flex",
            flexDirection: "row",
          }}
        >
          <SingleDatePicker
            label="Before"
            onBlur={(e) => {
              if (useOnBlur) {
                onChange(`${identifier}${lt_suffix}`, e ?? "");
                closeMenu && closeMenu();
              }
            }}
            onChange={(e) =>
              !useOnBlur && onChange(`${identifier}${lt_suffix}`, e ?? "")
            }
            value={ltDate}
            style={{
              flexGrow: 1,
              marginRight: gridSpacing[2],
              marginBottom: 0,
            }}
          />
          <SingleDatePicker
            label="After"
            onBlur={(e) => {
              if (useOnBlur) {
                onChange(`${identifier}${gt_suffix}`, e ?? "");
                closeMenu && closeMenu();
              }
            }}
            onChange={(e) =>
              !useOnBlur && onChange(`${identifier}${gt_suffix}`, e ?? "")
            }
            value={gtDate}
            style={{ flexGrow: 1, marginBottom: 0 }}
          />
        </div>
      </>
    );
  } else if (type === "DATE_BEFORE_AFTER") {
    return (
      <div
        style={{
          display: "flex",
          flexDirection: "row",
        }}
      >
        <SingleDatePicker
          label="After"
          testId="after-date-picker"
          onBlur={(e) => {
            if (useOnBlur) {
              onChange(`${identifier}${gt_suffix}`, e ?? "");
              closeMenu && closeMenu();
            }
          }}
          onChange={(e) =>
            !useOnBlur && onChange(`${identifier}${gt_suffix}`, e ?? "")
          }
          value={filters?.[`${identifier}${gt_suffix}`]?.[0]}
          style={{ flexGrow: 1, marginRight: gridSpacing[2], marginBottom: 0 }}
        />
        <SingleDatePicker
          label="Before"
          testId="before-date-picker"
          onBlur={(e) => {
            if (useOnBlur) {
              onChange(`${identifier}${lt_suffix}`, e ?? "");
              closeMenu && closeMenu();
            }
          }}
          onChange={(e) =>
            !useOnBlur && onChange(`${identifier}${lt_suffix}`, e ?? "")
          }
          value={filters?.[`${identifier}${lt_suffix}`]?.[0]}
          style={{ flexGrow: 1, marginBottom: 0 }}
        />
      </div>
    );
  } else if (type === "NUMERIC_RANGE") {
    return (
      <div
        style={{
          display: "flex",
          flexDirection: "row",
        }}
      >
        <Text
          placeholder="Minimum (optional)"
          type="number"
          min={0}
          onBlur={(e: React.ChangeEvent<HTMLInputElement>) =>
            useOnBlur && onChange(`${identifier}${gt_suffix}`, e.target.value)
          }
          onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
            !useOnBlur && onChange(`${identifier}${gt_suffix}`, e.target.value)
          }
          onEnter={(e: React.BaseSyntheticEvent) =>
            useOnBlur
              ? onChange(`${identifier}${gt_suffix}`, e.target.value)
              : closeMenu && closeMenu()
          }
          value={filters?.[`${identifier}${gt_suffix}`]?.[0]}
          style={{ flexGrow: 1, marginRight: gridSpacing[2], marginBottom: 0 }}
          testId="min-input"
        />
        <Text
          placeholder="Maximum (optional)"
          type="number"
          min={0}
          onBlur={(e: React.ChangeEvent<HTMLInputElement>) =>
            useOnBlur && onChange(`${identifier}${lt_suffix}`, e.target.value)
          }
          onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
            !useOnBlur && onChange(`${identifier}${lt_suffix}`, e.target.value)
          }
          onEnter={(e: React.BaseSyntheticEvent) =>
            useOnBlur
              ? onChange(`${identifier}${lt_suffix}`, e.target.value)
              : closeMenu && closeMenu()
          }
          value={filters?.[`${identifier}${lt_suffix}`]?.[0]}
          style={{ flexGrow: 1, marginBottom: 0 }}
          testId="max-input"
        />
      </div>
    );
  } else if (type === "BOOLEAN") {
    return (
      <Select
        emptyOption
        placeholder="Select True False or No Filter"
        value={filters?.[identifier]?.[0]}
        onChange={(e) => onChange(identifier, e)}
        options={[
          {
            value: "True",
            label: "True",
          },
          {
            value: "False",
            label: "False",
          },
        ]}
      />
    );
  } else if (type === "FREEFORM") {
    return (
      <Text
        autofocus
        placeholder="Enter text to search this field"
        onBlur={(e: React.ChangeEvent<HTMLInputElement>) => {
          if (useOnBlur) {
            onChange(identifier, e.target.value);
            closeMenu && closeMenu();
          }
        }}
        onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
          useOnBlur
            ? setCurrentValue(e.target.value)
            : onChange(identifier, e.target.value)
        }
        onEnter={(e: React.BaseSyntheticEvent) => {
          onChange(identifier, e.target.value);
          closeMenu && closeMenu();
        }}
        value={useOnBlur ? currentValue : filters?.[identifier]?.[0]}
        testId="freeform-input"
        style={{ marginBottom: 0 }}
      />
    );
    // @ts-expect-error - This may also be a type from the API
  } else if (type === "DATE") {
    return (
      <SingleDatePicker
        label="Date"
        onBlur={(e) => {
          if (useOnBlur) {
            onChange(identifier, e ?? "");
            closeMenu && closeMenu();
          }
        }}
        onChange={(e) => !useOnBlur && onChange(identifier, e ?? "")}
        value={filters?.[identifier]?.[0]}
        style={{ marginBottom: 0 }}
      />
    );
  }

  return null;
};

export default Options;
