import DATA_HUB_URLS from "./urls";

export default [
  {
    appTitle: true,
    header: "Data Hub",
    icon: "fa-kit fa-hdp-app-data-hub",
    baseRoute: DATA_HUB_URLS.baseUrl,
  },
  {
    header: "Monitoring",
    children: [
      {
        name: "Orchestration Jobs",
        route: DATA_HUB_URLS.DATA_ORCHESTRATION_MONITORING,
        permissions: ["monitoring.view_pipelinejob"],
      },
      {
        name: "Data Processing",
        route: DATA_HUB_URLS.OPERATIONS_MONITORING_DATA_PROCESSING,
        permissions: ["monitoring.view_filerecord"],
      },
      {
        name: "Job Processing",
        route: DATA_HUB_URLS.OPERATIONS_MONITORING_JOB_PROCESSING,
        permissions: ["monitoring.view_processevent"],
      },
    ],
  },
  {
    header: "Data Model",
    children: [
      {
        name: "Catalog Overview",
        route: DATA_HUB_URLS.DATA_MODEL_CATALOG_OVERVIEW,
        permissions: ["data_model.view_datamodel"],
      },
      {
        name: "Lineage Graph",
        route: DATA_HUB_URLS.DATA_MODEL_LINEAGE_GRAPH,
        permissions: ["lineage.view_lineagegraph"],
      },
      {
        name: "ERD",
        route: DATA_HUB_URLS.DATA_MODEL_ERD,
        permissions: ["data_model.view_datamodel"],
      },
    ],
  },
  {
    header: "Orchestration",
    children: [
      {
        name: "Projects",
        route: DATA_HUB_URLS.DATA_ORCHESTRATION_SOURCES,
        permissions: ["orchestration.view_source"],
      },
      {
        name: "Connections",
        route: DATA_HUB_URLS.DATA_ORCHESTRATION_CONNECTIONS,
        permissions: ["orchestration.view_connection"],
      },
    ],
  },
  {
    header: "Integration",
    children: [
      {
        name: "Data Mapping",
        route: DATA_HUB_URLS.INTEGRATION_DATA_MAPPING,
        permissions: ["data_mapping.view_configuration"],
      },
      {
        name: "Data Test",
        route: DATA_HUB_URLS.INTEGRATION_DATA_TEST,
        permissions: ["data_quality.view_mappingtest"],
      },
    ],
  },
  {
    header: "Transformation",
    children: [
      {
        name: "Recoding",
        route: DATA_HUB_URLS.TRANSFORMATION_RECODING,
        permissions: ["recode.view_ruleset"],
      },
      {
        name: "Value Lists",
        route: DATA_HUB_URLS.TRANSFORMATION_VALUE_LISTS,
        permissions: ["value_list.view_valuelist"],
      },
      {
        name: "Managed Tables",
        route: DATA_HUB_URLS.TRANSFORMATION_MANAGED_TABLES,
        permissions: ["custom_tables.view_customtable"],
      },
    ],
  },
  {
    header: "Activity Stream",
    children: [
      {
        name: "Activity Stream",
        route: DATA_HUB_URLS.ACTIVITY_STREAM,
        permissions: ["auditlog.view_logentry"],
      },
    ],
  },
];
