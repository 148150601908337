const baseUrl = "/data-hub";

export default {
  baseUrl,
  DATA_ORCHESTRATION_SOURCES: `${baseUrl}/data-orchestration/sources`,
  DATA_ORCHESTRATION_CONNECTIONS: `${baseUrl}/data-orchestration/connections`,
  DATA_ORCHESTRATION_MONITORING: `${baseUrl}/operations/monitoring/data-orchestration`,
  INTEGRATION_DATA_MAPPING: `${baseUrl}/integration/data-mapping`,
  INTEGRATION_DATA_TEST: `${baseUrl}/integration/data-test`,
  TRANSFORMATION_RECODING: `${baseUrl}/transformation/recoding`,
  TRANSFORMATION_RECODING_VALUE_MAPPING: `${baseUrl}/transformation/recoding/value-mapping`,
  TRANSFORMATION_RECODING_RULE_SET: `${baseUrl}/transformation/recoding/rule-set`,
  TRANSFORMATION_VALUE_LISTS: `${baseUrl}/transformation/value-lists`,
  TRANSFORMATION_MANAGED_TABLES: `${baseUrl}/transformation/managed-tables`,
  OPERATIONS_MONITORING_DATA_PROCESSING: `${baseUrl}/operations/monitoring/data-processing`,
  OPERATIONS_MONITORING_JOB_PROCESSING: `${baseUrl}/operations/monitoring/job-processing`,
  DATA_MODEL_ERD: `${baseUrl}/data-model/erd`,
  DATA_MODEL_LINEAGE_GRAPH: `${baseUrl}/data-model/lineage-graph`,
  DATA_MODEL_CATALOG_OVERVIEW: `${baseUrl}/data-model/catalog-overview`,
  ACTIVITY_STREAM: `${baseUrl}/activity-stream`,
};
