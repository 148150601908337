import React, { useEffect, useState } from "react";

import { Popover, gridSpacing } from "@lucernahealth/lucerna-health-ui";
import { styled } from "styled-components";

import type { FilterOptionProps } from "./AdvancedFilters";
import FilterButton from "./FilterButton";
import Options from "./Options";
import { colorTheme } from "~/src/utils";

// eslint-disable-next-line @atlaskit/design-system/ensure-design-token-usage
const ContentContainer = styled.div`
  margin-top: 5px;
  min-width: 525px;
  background: ${colorTheme("white")};
  border: 1px solid ${colorTheme("neutralL4")};
  border-radius: 8px;
  padding: ${gridSpacing[3]}px;
  // eslint-disable-next-line styled-no-color-value/no-color-value
  box-shadow: 0px 8px 32px 0px rgba(0, 0, 0, 0.07);
`;

type CustomOptionsPopoverProps = {
  opened?: boolean;
  data: FilterOptionProps;
  filters: Record<string, string[]>;
  menuClosed: () => void;
  onChange: (filters: Record<string, string[]>) => void;
  clearValues?: () => void;
  useOnBlur?: boolean;
};

const CustomOptionsPopover = ({
  opened,
  data,
  filters,
  menuClosed,
  onChange,
  clearValues,
  useOnBlur,
}: CustomOptionsPopoverProps) => {
  const [newValue, setNewValue] = useState<Record<string, string[]>>(filters);
  const [menuOpened, setMenuOpened] = useState(opened ?? false);
  const gtValue = filters?.[`${data.identifier}${data.gt_suffix}`];
  const ltValue = filters?.[`${data.identifier}${data.lt_suffix}`];

  const values = [];

  useEffect(() => {
    setNewValue(filters);
  }, [filters]);

  if (gtValue?.[0]) {
    values.push(gtValue[0]);
  }

  if (ltValue?.[0]) {
    values.push(ltValue[0]);
  }

  if (filters[`${data.identifier}`]) {
    values.push(filters[`${data.identifier}`][0]);
  }

  const menuOpenChange = (
    opened: boolean,
    incomingValue?: Record<string, string[]>,
  ) => {
    if (opened) {
      setMenuOpened(true);
      setNewValue(filters);
    } else {
      const newFilters = { ...(incomingValue || newValue) };

      if (newFilters[`${data.identifier}${data.gt_suffix}`] === undefined) {
        delete newFilters[`${data.identifier}${data.gt_suffix}`];
      }

      if (newFilters[`${data.identifier}${data.lt_suffix}`] === undefined) {
        delete newFilters[`${data.identifier}${data.lt_suffix}`];
      }

      onChange(newFilters);
      menuClosed();
      setMenuOpened(false);
      setNewValue({});
    }
  };

  return (
    <Popover open={menuOpened} onOpenChange={menuOpenChange}>
      <Popover.Trigger>
        <FilterButton
          label={data.label}
          values={values}
          clearValues={clearValues}
        />
      </Popover.Trigger>
      <Popover.Content align="start">
        <ContentContainer>
          {/* This button is a hack so the user won't focus on the textboxes */}
          {data.type !== "FREEFORM" && (
            <button style={{ position: "absolute", opacity: 0 }} />
          )}
          <Options
            {...data}
            useOnBlur={useOnBlur}
            identifier={data.identifier
              ?.replace(`_${data.gt_suffix}`, "")
              .replace(`_${data.lt_suffix}`, "")}
            filters={newValue}
            onChange={(id, value) => {
              if (useOnBlur) {
                menuOpenChange(false, { ...newValue, [id]: [value] });
              } else {
                setNewValue({ ...newValue, [id]: [value] });
              }
            }}
            closeMenu={() => {
              if (!useOnBlur) {
                setMenuOpened(false);
                menuOpenChange(false);
              }
            }}
          />
        </ContentContainer>
      </Popover.Content>
    </Popover>
  );
};
export default CustomOptionsPopover;
