import React from "react";

import PropTypes from "prop-types";
import { styled, css } from "styled-components";

import { colorTheme } from "@utils";

const TableIconContainer = styled.div`
  ${({ direction }) =>
    direction === "center" &&
    css`
      display: flex;
      flex-direction: row;
      width: 100%;
      justify-content: center;
      align-items: center;
    `}

  ${({ clickable }) =>
    clickable &&
    css`
      &:hover {
        cursor: pointer;
      }
    `}
`;

const IconTrueFalse = ({ value, style, direction, onClick }) => {
  const renderIcon = () => {
    if (value === true) {
      return (
        <i
          className="fa-solid fa-check"
          style={{ color: colorTheme("success") }}
        />
      );
    } else if (value === false) {
      return (
        <i
          className="fa-solid fa-xmark"
          style={{ color: colorTheme("danger") }}
        />
      );
    }
    return "-";
  };

  return (
    <TableIconContainer
      direction={direction}
      style={style}
      clickable={!!onClick}
      onClick={onClick}
    >
      {renderIcon()}
    </TableIconContainer>
  );
};

IconTrueFalse.propTypes = {
  value: PropTypes.oneOfType([PropTypes.bool, PropTypes.string]),
  style: PropTypes.object,
  direction: PropTypes.string,
  onClick: PropTypes.func,
};

export default IconTrueFalse;
