import React from "react";

import { gridSpacing } from "@lucernahealth/lucerna-health-ui";

import { colorTheme } from "@utils";

const CIRCLE_SIZES = {
  1: 20,
  2: 24,
  3: 28,
} as const;

const FONT_SIZES = {
  1: 12,
  2: 14,
  3: 16,
} as const;

interface BadgeProps {
  size?: 1 | 2 | 3;
  style?: React.CSSProperties;
  children: React.ReactNode;
}

const Badge = ({ size = 1, style = {}, children }: BadgeProps) => (
  <div
    style={{
      width: CIRCLE_SIZES[size],
      height: CIRCLE_SIZES[size],
      backgroundColor: colorTheme("neutralL4"),
      borderRadius: "50%",
      color: colorTheme("neutralL1"),
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      textAlign: "center",
      fontSize: FONT_SIZES[size],
      marginRight: gridSpacing[2],
      ...style,
    }}
  >
    {children}
  </div>
);

export default Badge;
